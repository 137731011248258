import { UserSettingsService } from '@awork/_shared/services/user-settings-service/user-settings.service'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core'
import { Project } from '@awork/features/project/models/project.model'
import { User } from '@awork/features/user/models/user.model'
import { Company } from '@awork/features/company/models/company.model'
import { debounceTime, filter, Subject } from 'rxjs'
import { Task } from '@awork/features/task/models/task.model'
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router'
import { AppQuery } from '@awork/core/state/app.query'
import { MyTaskId, TaskViewList } from '@awork/features/task/models/task-view-list.model'
import { SignalStoreService } from '@awork/core/state/signal-store/signalStore.service'
import { BackRouteInfo, NavigationHistoryItem, NavigationInfo } from './types'

const MAX_ROUTER_HISTORY = 10
// This path patterns are just proxy to some other subnavigation and should not be
// added to the router history
const EXCLUDE_PATTERNS = [
  '(\\/)',
  '(\\/settings)',
  '(\\/planner\\/team)',
  '(\\/projects\\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[[0-9a-f]{4}-[0-9a-f]{12})',
  '(\\/my\\/dashboard\\/\\(detailModal:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[[0-9a-f]{4}-[0-9a-f]{12}\\))'
]
const excludeURLRegExp = new RegExp(`^(${EXCLUDE_PATTERNS.join('|')})$`)

@Injectable({ providedIn: 'root' })
export class NavigationService {
  // History of every routed url
  routerHistory = signal<string[]>([])
  routerHistoryIndex = signal<number>(-1)
  isRouterHistoryBeginning = computed(() => this.routerHistoryIndex() === 0)
  isRouterHistoryEnd = computed(() => this.routerHistoryIndex() === this.routerHistory().length - 1)

  // Navigation history for real pages and objects (used by header component)
  public navigationHistory: NavigationHistoryItem[] = []
  public navigationHistory$: Subject<NavigationHistoryItem[]> = new Subject<NavigationHistoryItem[]>()
  private saveNavigationHistory$: Subject<NavigationHistoryItem[]> = new Subject<NavigationHistoryItem[]>()
  private navigationHistoryPersistanceKey = 'navigationHistory'

  navigationInfos: NavigationInfo[] = []

  _backRouteInfo: WritableSignal<BackRouteInfo> = signal(null)
  backButtonClicked$ = new Subject<void>()

  constructor(
    private router: Router,
    private userSettingsService: UserSettingsService,
    private browserService: BrowserService,
    private route: ActivatedRoute,
    private appQuery: AppQuery,
    private signalStoreService: SignalStoreService
  ) {
    this.signalStoreService.selectOnStorageInit().subscribe(() => {
      this.appQuery
        .selectIsLoggedIn()
        .pipe(filter(isLoggedIn => isLoggedIn))
        .subscribe(() => {
          // Fetch the navigation history from the user settings
          this.fetchNavigationHistoryForUser()
        })
    })

    // subscribe to the save history to user settings event and perform the saving
    this.saveNavigationHistory$.pipe(debounceTime(1000)).subscribe(arrayToSave => {
      // simply object by stripping out the entity object
      const cleanArray = arrayToSave.map(item => {
        return {
          entityId: item.entityId,
          title: item.title,
          type: item.type,
          url: item.url
        }
      })

      // save data to API
      this.userSettingsService
        .setUserSetting(this.navigationHistoryPersistanceKey, JSON.stringify(cleanArray))
        .subscribe()
    })

    // Get navigation info and set titles etc. before any navigation happened
    const root: ActivatedRoute = this.route.root
    this.navigationInfos = this.getNavigationInfo(root)
    this.addPageToNavigationHistory()

    // subscribe to the NavigationEnd event to update the info
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      event = event as NavigationEnd

      this.navigationInfos = this.getNavigationInfo(root)

      // Add page to the navigation history and update the titles
      this.addPageToNavigationHistory()

      // Store the last route in the navigation service
      this.addRouteToHistory(event.url)

      this.getBackRoute(this.route.root, true)
    })
  }

  /**
   * Recursively searches for the back route info in the route tree
   * @param {ActivatedRoute} root
   * @param {boolean} reset - if true, the back route info signal will be reset
   */
  private getBackRoute(root: ActivatedRoute, reset?: boolean): void {
    const BACK_ROUTE_DATA = 'backRoute'
    const BACK_ROUTE_TOOLTIP_DATA = 'backRouteTooltip'
    const BACK_ROUTE_CONFIRM_DATA = 'backRouteConfirm'

    if (reset) {
      this._backRouteInfo.set(null)
    }

    for (const child of root.children) {
      const childBackRoute = child.snapshot.data?.[BACK_ROUTE_DATA]
      const childBackRouteTooltip = child.snapshot.data?.[BACK_ROUTE_TOOLTIP_DATA]
      const childBackRouteConfirm = child.snapshot.data?.[BACK_ROUTE_CONFIRM_DATA]

      if (childBackRoute && childBackRouteTooltip) {
        this._backRouteInfo.set({
          route: childBackRoute,
          tooltip: childBackRouteTooltip,
          needsConfirm: childBackRouteConfirm
        })
      }

      this.getBackRoute(child)
    }
  }

  /**
   * Fetches the last history items from the user settings service3
   * @param url
   */
  fetchNavigationHistoryForUser(): void {
    // get the last history items from the user settings service
    this.userSettingsService.getUserSetting(this.navigationHistoryPersistanceKey).subscribe(item => {
      if (item && item.body && item.body.value) {
        try {
          const data = JSON.parse(item.body.value)

          // setting the history
          this.navigationHistory = data
          this.navigationHistory$.next(this.navigationHistory)
        } catch {}
      }
    })
  }

  /**
   * Adds an entity into the navigation history
   */
  addEntityToNavigationHistory(
    type: 'company' | 'user' | 'project' | 'task' | 'taskView',
    entity: Project | Task | User | Company | TaskViewList
  ): void {
    let entityId: string
    let icon: string
    let urlPath = '/'

    if (entity instanceof Project) {
      entityId = entity.id
      urlPath += `projects/${entityId}`
      this.browserService.setAppTitle(`${q.translations.entities.project} • ${entity.name}`)
    } else if (entity instanceof User) {
      entityId = entity.id
      urlPath += `users/${entityId}`
      this.browserService.setAppTitle(`${q.translations.entities.user} • ${entity.firstName}`)
    } else if (entity instanceof Company) {
      entityId = entity.id
      urlPath += `companies/${entityId}`
      this.browserService.setAppTitle(`${q.translations.entities.company} • ${entity.name}`)
    } else if (entity instanceof Task) {
      entityId = entity.id
      urlPath += `tasks/${entityId}`
      this.browserService.setAppTitle(`${q.translations.entities.task} • ${entity.name}`)
    } else if (entity instanceof TaskViewList) {
      entityId = entity.id || MyTaskId
      urlPath += `tasks/filters/${entityId}`
      icon = 'filter_alt'
      this.browserService.setAppTitle(`${q.translations.titles.taskViews} • ${entity.name}`)
    }

    if (entity && entityId && type) {
      // remove all occurrences of this item from the array
      this.navigationHistory = this.navigationHistory.filter(item => {
        return !(item.type === type && item.entityId === entityId)
      })

      // push object to array
      const newItem: NavigationHistoryItem = {
        url: urlPath,
        title: '',
        type,
        entityId,
        entity,
        icon
      }

      // add item to array and update the info
      this.pushNavigationItemToArray(newItem)
    }
  }

  /**
   * Rmove an entity into the navigation history
   */
  removeEntityToNavigationHistory(
    type: 'company' | 'user' | 'project' | 'task' | 'taskView',
    entity: Project | Task | User | Company | TaskViewList
  ): void {
    const itemToRemove = this.navigationHistory.find(item => item.type === type && item.entityId === entity.id)
    if (itemToRemove) {
      this.navigationHistory = this.navigationHistory.filter(item => item !== itemToRemove)
    }
  }

  /**
   * Sets a non-entity page to navigation history with a given url
   */
  setPageToNavigationHistory(pageUrl: string, pageTitle: string): void {
    // push object to array
    const newItem: NavigationHistoryItem = {
      url: pageUrl,
      title: pageTitle,
      type: 'page'
    }

    // remove all occurrences of this item from the array
    this.navigationHistory = this.navigationHistory.filter(item => {
      return item.url !== pageUrl
    })

    // add item to array and update all the info
    this.pushNavigationItemToArray(newItem)

    this.browserService.setAppTitle(pageTitle)
  }

  /**
   * Add non-entity page to navigation history
   */
  addPageToNavigationHistory(): void {
    if (this.navigationInfos && this.navigationInfos.length > 0) {
      const lastRouteChild = this.navigationInfos[this.navigationInfos.length - 1]
      if (lastRouteChild && lastRouteChild.label) {
        // remove all occurences of this item from the array
        this.navigationHistory = this.navigationHistory.filter(item => {
          return !(item.type === 'page' && item.title === lastRouteChild.label)
        })

        // push object to array
        const newItem: NavigationHistoryItem = {
          url: lastRouteChild.url,
          title: lastRouteChild.label,
          type: 'page'
        }

        // add item to array and update all the info
        this.pushNavigationItemToArray(newItem)

        // set the app title
        const currentPageTitle = lastRouteChild.label
        this.browserService.setAppTitle(currentPageTitle)
      }
    }
  }

  /**
   * Adds the navigation item to the array and checks the array
   * @param newItem
   */
  private pushNavigationItemToArray(newItem: NavigationHistoryItem): void {
    // add to array
    if (!(newItem.type === 'page' && (newItem.title === '⚠️' || newItem.title === '404'))) {
      this.navigationHistory.push(newItem)
    }

    // pop out last item if arry is too long
    if (this.navigationHistory.length > 5) {
      this.navigationHistory.shift()
    }

    // save new array
    const arrayToSave = this.navigationHistory
    this.saveNavigationHistory$.next(arrayToSave)

    // emit the next item to the observable
    this.navigationHistory$.next(this.navigationHistory)
  }

  /**
   * Emits the newest version of the page hisotry in the observable
   */
  public refreshNavigationHistory(): void {
    this.navigationHistory$.next(this.navigationHistory)
  }

  /**
   * Returns array of NavigationInfo objects from the active route
   * @param { ActivatedRoute } route
   * @param {string} url
   * @param { NavigationInfo[] } navigationInfos
   */
  private getNavigationInfo(
    route: ActivatedRoute,
    url: string = '',
    navigationInfos: NavigationInfo[] = []
  ): NavigationInfo[] {
    const ROUTE_DATA_TITLE = 'title'
    const ROUTE_DATA_HISTORY_URL = 'historyUrl'

    if (route) {
      // get the child routes
      const children: ActivatedRoute[] = route.children

      // return if there are no more children
      if (children.length === 0) {
        return navigationInfos
      }

      // iterate over each children
      for (const child of children) {
        // verify primary route
        if (child.outlet !== PRIMARY_OUTLET) {
          continue
        }

        // verify the custom data property "title" is specified on the route
        if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_TITLE)) {
          // return this.getNavigationInfo(child, url, navigationInfos)
        }

        // get the route's URL segment
        const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/')

        // append route URL to URL
        if (routeURL && routeURL !== '') {
          url += `/${routeURL}`
        }

        // add the navigational object
        const label = child.snapshot.data[ROUTE_DATA_TITLE]
        const title: NavigationInfo = {
          label,
          url: child.snapshot.data[ROUTE_DATA_HISTORY_URL] || url
        }

        // Do not exclude the rout if it has an explicit history url, remove possible duplicates
        if (child.snapshot.data[ROUTE_DATA_HISTORY_URL]) {
          navigationInfos = navigationInfos.filter(info => info.url !== child.snapshot.data[ROUTE_DATA_HISTORY_URL])
        }
        // when the url already includes an id, then do return any new navigation object
        else if (
          url.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[[0-9a-f]{4}-[0-9a-f]{12}/gi) ||
          url.includes(MyTaskId)
        ) {
          return null
        }

        // avoid doubles due to redirects
        if (title && title.label) {
          if (!navigationInfos.find(bc => bc.label === title.label)) {
            navigationInfos.push(title)
          }
        }

        // recursive over all children
        return this.getNavigationInfo(child, url, navigationInfos)
      }
    }
    return navigationInfos
  }

  /**
   * Gets the back route info extracted from the router config
   */
  get backRouteInfo(): Signal<BackRouteInfo> {
    return this._backRouteInfo.asReadonly()
  }

  // ---------------------------- SAVING THE ROUTING HISTORY (SIMPLE; EVERY ROUTE) ------------------------------

  /**
   * Add a the new url to the router history array
   * If the length exceeds 10, the oldest item will be thrown out
   * @param {string} url
   */
  addRouteToHistory(url: string): void {
    const currentHistoryURL = this.routerHistory()[this.routerHistoryIndex()]

    if (excludeURLRegExp.test(url)) {
      return
    }

    if (this.routerHistoryIndex() < this.routerHistory().length - 1 && currentHistoryURL !== url) {
      this.routerHistory.set([...this.routerHistory().slice(0, this.routerHistoryIndex() + 1), url])
      this.routerHistoryIndex.update(value => value + 1)
      return
    }

    if (this.isRouterHistoryEnd() && currentHistoryURL !== url) {
      this.routerHistory.update(value => value.concat(url))
      this.routerHistoryIndex.set(Math.min(MAX_ROUTER_HISTORY - 1, this.routerHistoryIndex() + 1))
    }

    if (this.routerHistory().length > MAX_ROUTER_HISTORY) {
      this.routerHistory.update(value => value.slice(1))
    }
  }

  /**
   * Returns the last route if stored, else returns an empty string.
   * The last route is the second last item, because the last item is the current route
   */
  getLastRoute(): string {
    if (this.routerHistory() && this.routerHistory().length > 1) {
      return this.routerHistory()[this.routerHistory().length - 2]
    }
    return ''
  }

  /**
   * Return the router history array
   * @return {string[]}
   */
  getRouterHistory(): string[] {
    return this.routerHistory()
  }

  /**
   * Goes back to the previous url in the router history
   */
  goBackHistory(): void {
    const newHistoryIndex = this.routerHistoryIndex() - 1
    if (newHistoryIndex < 0) {
      return
    }

    const url = this.routerHistory()[newHistoryIndex]
    this.routerHistoryIndex.update(value => value - 1)
    this.router.navigateByUrl(url)
  }

  /**
   * Goes forward to the next url in the router history
   */
  goForwardHistory(): void {
    const newHistoryIndex = this.routerHistoryIndex() + 1
    if (newHistoryIndex >= this.routerHistory().length) {
      return
    }

    const url = this.routerHistory()[newHistoryIndex]
    this.routerHistoryIndex.update(value => value + 1)
    this.router.navigateByUrl(url)
  }
}
